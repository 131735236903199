<template>
  <ExamIframe :path="path"></ExamIframe>
</template>

<script>
import ExamIframe from './ExamIframe'

export default {
    name: "ExamPaperDo",
    components:{
      ExamIframe
    },
    data () {
      return {
        path: 'do'
      }
    },
    mounted(){
      
    },
    methods: {}
  }
</script>
<style scoped>
</style>